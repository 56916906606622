<template>
  <v-container fill-height>
    <v-snackbar
        v-model="notification"
        :color="'success'"
        :multi-line="false"
        :timeout="3000"
        :vertical="false"
        :top="true">
      {{ notification_message }}
      <v-btn dark text
             @click="notification = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card title="Wellstar Organisations"
                       text="Show Organisations">

          <template :slot="'header_actions'">
            <v-btn small fab rounded
                   v-tooltip="'Add new Organisation'" color="white"
                   @click="edit_organisation_data({})">
              <v-icon color="primary"> mdi-plus</v-icon>
            </v-btn>
          </template>

          <template>
            <cpanel-filter-data :original_url="filter_url"
                                :fields_to_filter="filter_fields"
                                @trigger-new-search="triggerSearch">
            </cpanel-filter-data>

            <v-data-table expand
                          :headers="customer_headers"
                          :items="organisations"
                          :loading="loading"
                          :loading-text="'Loading please wait'"
                          :single-expand="singleExpand"
                          :expanded.sync="expanded"
                          show-expand
                          item-key="id">

              <template v-slot:expanded-item="{ headers, item, index }">
                <td :colspan="headers.length">
                  <v-card class="ma-2 py-2" elevation="6">
                    <v-layout align-center justify-start row wrap
                              class="my-0">
                      <v-flex class="px-4 py-2 ma-0" xs12 md6
                              v-for="(item_field, item_key) in {'address': 'Address',
                                                                              'country': 'Country',
                                                                              'zip_code': 'Zip Code',
                                                                              'email': 'Email',
                                                                              'website': 'Website'}"
                              :key="item_field">
                        <label class="uk-text-bold">{{ item_field }}: </label>
                        <span v-show="item_key !== 'country' && item_key !== 'email'"
                              :style="{ color: item.user_data.profile[item_key]? 'black' : 'red'}">{{
                            item.user_data.profile[item_key] || "Not set"
                          }}</span>
                        <span v-if="item.user_data.profile.country && item_key === 'country'">
                                                    {{ item.user_data.profile.country_data['name'] }}
                                                </span>
                        <span v-if="item.user_data.email && item_key === 'email'">
                                                    {{ item.user_data.email }}
                                                </span>
                      </v-flex>
                      <v-flex class="mx-5 mb-0 mt-3" xs12 justify-end v-if="item.type === 1">
                        <h3 class="headline mb-4">
                          Change Sourcer
                        </h3>
                        <v-alert type="info">
                          Sourcers on the platform are set per product not per factory
                          if you choose to do this action you will change the sourcer
                          for every product relating to this factory.
                        </v-alert>
                        <v-btn
                            color="blue-grey darken-4"
                            block
                            outlined
                            @click="open_change_sourcer(item)"
                        >
                          Change Sourcer
                        </v-btn>
                      </v-flex>
                      <v-flex class="mx-5 mb-0 mt-3" xs12 justify-end>
                        <v-spacer></v-spacer>
                        <span class="my-1 uk-text-bold">Departments</span>
                      </v-flex>
                      <v-flex xs12>
                        <v-divider class="mt-1"></v-divider>
                      </v-flex>


                      <v-flex expand>
                        <table style="width:100%;">
                          <tr>
                            <th class="text-xs-left">Name</th>
                            <th class="text-xs-left"
                            >Users
                            </th>
                            <th nowrap><span>Markup</span></th>
                            <th>Actions</th>
                          </tr>
                          <tr v-if="item.departments.length" v-for="department in item.departments">
                            <td class="text-xs-left">{{ department.name }}</td>
                            <td class="text-xs-left">
                              <v-simple-table v-show="department.users.length">
                                <thead>
                                <tr>
                                  <th class="text-left">Email</th>
                                  <th class="text-left">Auth Groups</th>
                                  <th class="text-left">Act</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(dep_usr, index) in department.users"
                                    :key="dep_usr.id">
                                  <td>{{ dep_usr.username }}</td>
                                  <td>
                                                                        <span v-for="grp in dep_usr.groups">
                                                                            {{ grp.name }},
                                                                        </span>
                                  </td>
                                  <td>
                                    <v-btn
                                        color="blue-grey darken-4"
                                        text
                                        v-tooltip="'Remove User From Department'"
                                        rounded
                                        @click="remove_user(dep_usr, department, index)"
                                    >
                                      <v-icon>
                                        mdi-minus-circle-outline
                                      </v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                                </tbody>
                              </v-simple-table>

                              <div v-show="!department.users.length" class="text-center">
                                NO USERS
                              </div>
                            </td>
                            <td>
                              {{ department.percentage_for_shop }} %
                            </td>
                            <td>
                              <v-btn text rounded fab small
                                     v-if="user.profile_user"
                                     v-tooltip="!user.profile_user.department ? 'Use as this department': 'Cancel using as department'"
                                     @click="use_as_department(department.id === user.profile_user.department ? null:department.id)"
                                     :color="department.id === user.profile_user.department ? 'success': 'black'"
                              >

                                <v-icon>mdi-account-supervisor-circle</v-icon>
                              </v-btn>

                              <v-btn
                                  color="blue-grey darken-4"
                                  v-tooltip="'Add a new user'"
                                  @click="add_new_user(department)"
                                  text
                                  rounded
                              >
                                <v-icon>
                                  mdi-plus-circle-outline
                                </v-icon>
                              </v-btn>

                              <v-btn text rounded fab small
                                     v-tooltip="'Edit department'"
                                     @click="edit_department_data(department, item)">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <v-btn block class="primary"
                                     @click="edit_department_data({}, item)"
                                     v-tooltip="'Add Department'">
                                <v-icon>mdi-book-multiple-plus</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </table>
                      </v-flex>

                    </v-layout>
                  </v-card>
                </td>
              </template>
              <template v-slot:item.actions="{ item }">
                                <span @click.stop="">
                                  <v-btn text rounded fab small
                                         v-if="item.type === 1"
                                         v-tooltip="'View Factory products'"
                                         @click="view_factory(item)">
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>

                                            <v-btn text rounded fab small
                                                   v-tooltip="'Edit Organisation'"
                                                   @click="edit_organisation_data(item)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </span>
              </template>
            </v-data-table>


          </template>


        </material-card>
      </v-flex>
      <v-flex md12>
        <material-card title="Department Categories"
                       text="Department Category Information">

          <template :slot="'header_actions'">

          </template>

          <template>
            <v-data-table
                :headers="department_category_headers"
                :items="department_categories"
                :loading="loading"
                :loading-text="'Loading please wait'"
                :hide-default-footer="true"
                :items-per-page="50"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                    color="blue-grey darken-4"
                    @click="edit_category_sourcers(item)"
                    text
                    fab
                    small
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.sourcers="{ item }">
                <div v-for="sourcer in item.sourcers">
                  {{ sourcer.username }}
                </div>
              </template>
            </v-data-table>
          </template>
        </material-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="edit_organisation_dialog" persistent width="600px">
      <v-card class="px-5">
        <v-flex xs12>
          <v-autocomplete
              v-model="editOrganisation.user"
              :loading="loading"
              :items="users"
              item-text="username"
              item-value="id"
              :search-input.sync="search_users"
              cache-items
              flat
              hide-no-data
              hide-details
              label="User"
          ></v-autocomplete>
          <v-switch v-model="editOrganisation.new_user" label="New User"></v-switch>

        </v-flex>
        <v-flex xs12 v-show="editOrganisation.new_user === true">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="editOrganisation.first_name"
                    :counter="100"
                    label="First Name"

                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="editOrganisation.last_name"
                    :counter="100"
                    label="Last Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              </v-col>
              <v-col cols="12" sm="6" md="4">
              </v-col>
              <v-col cols="12" sm="6" md="4">


                <v-text-field
                    v-model="editOrganisation.email"
                    :counter="100"
                    type="email"
                    label="Email"
                ></v-text-field>
                <v-text-field
                    v-model="editOrganisation.password"
                    :counter="50"
                    type="password"
                    label="Password"
                ></v-text-field>
                <v-text-field
                    v-model="editOrganisation.confirm_password"
                    :counter="50"
                    type="password"
                    label="Password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-flex>

        <v-flex xs12>
          <!-- User Specific Details -->
          <v-text-field
              v-model="editOrganisation.name"
              :counter="200"
              label="Name"
              required
              :error-messages="checkName"
              @input="$v.editOrganisation.name.$touch()"
              @blur="$v.editOrganisation.name.$touch()"

          ></v-text-field>

          <v-text-field
              v-model="editOrganisation.user_data.profile.address"
              :counter="200"
              required
              :error-messages="checkAddress"
              @input="$v.editOrganisation.user_data.profile.address.$touch()"
              @blur="$v.editOrganisation.user_data.profile.address.$touch()"
              label="Address"

          ></v-text-field>
          <v-text-field
              v-model="editOrganisation.user_data.profile.region"
              required
              :error-messages="checkRegion"
              @input="$v.editOrganisation.user_data.profile.region.$touch()"
              @blur="$v.editOrganisation.user_data.profile.region.$touch()"
              label="Region"

          ></v-text-field>
          <v-text-field
              v-model="editOrganisation.user_data.profile.zip_code"
              required
              :error-messages="checkZipCode"
              @input="$v.editOrganisation.user_data.profile.zip_code.$touch()"
              @blur="$v.editOrganisation.user_data.profile.zip_code.$touch()"
              label="Zip Code"

          ></v-text-field>
          <v-flex xs12>
            <v-select
                :error-messages="checkCountry"
                @input="$v.editOrganisation.user_data.profile.country.$touch()"
                @blur="$v.editOrganisation.user_data.profile.country.$touch()"
                required
                v-model="editOrganisation.user_data.profile.country"
                :items="countries"
                item-value="id"
                item-text="name"
                label="Country"
            ></v-select>
          </v-flex>


          <v-text-field
              v-model="editOrganisation.user_data.profile.phone"
              required
              :error-messages="checkPhone"
              @input="$v.editOrganisation.user_data.profile.phone.$touch()"
              @blur="$v.editOrganisation.user_data.profile.phone.$touch()"
              label="Phone"
          ></v-text-field>
          <v-text-field
              v-model="editOrganisation.user_data.profile.phone_extension"
              label="Phone EXT"
              required
              :error-messages="checkPhoneExtension"
              @input="$v.editOrganisation.user_data.profile.phone_extension.$touch()"
              @blur="$v.editOrganisation.user_data.profile.phone_extension.$touch()"
          ></v-text-field>
          <v-text-field
              v-model="editOrganisation.user_data.profile.website"
              label="Website"
              required
              :error-messages="checkWebsite"
              @input="$v.editOrganisation.user_data.profile.website.$touch()"
              @blur="$v.editOrganisation.user_data.profile.website.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-select
              v-model="editOrganisation.category"
              :items="organisation_categories"
              required
              :error-messages="checkCategory"
              @input="$v.editOrganisation.category.$touch()"
              @blur="$v.editOrganisation.category.$touch()"
              item-value="id"
              item-text="name"
              label="Category"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-select
              v-model="editOrganisation.type"
              :items="organisation_types"
              item-value="id"
              item-text="name"
              required
              :error-messages="checkType"
              @input="$v.editOrganisation.type.$touch()"
              @blur="$v.editOrganisation.type.$touch()"
              label="Type of Org"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
              v-model="editOrganisation.store_count"
              required
              :error-messages="checkStoreCount"
              @input="$v.editOrganisation.store_count.$touch()"
              @blur="$v.editOrganisation.store_count.$touch()"
              label="Store Count"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
              v-model="editOrganisation.invoice_code"
              label="Inv Code"
              required
              :error-messages="checkInvoiceCode"
              @input="$v.editOrganisation.invoice_code.$touch()"
              @blur="$v.editOrganisation.invoice_code.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-select
              v-model="editOrganisation.payment_terms"
              :items="payment_terms"
              item-value="id"
              item-text="name"
              label="Payment Terms"
              required
              :error-messages="checkPaymentTerms"
              @input="$v.editOrganisation.payment_terms.$touch()"
              @blur="$v.editOrganisation.payment_terms.$touch()"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-select
              v-model="editOrganisation.payment_days"
              :items="payment_days"
              item-value="id"
              item-text="name"
              label="Payment Days"
              required
              :error-messages="checkPaymentDays"
              @input="$v.editOrganisation.payment_days.$touch()"
              @blur="$v.editOrganisation.payment_days.$touch()"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-select
              v-model="editOrganisation.payment_deposit_amount"
              :items="payment_deposit"
              item-value="id"
              item-text="name"
              label="Payment Deposit"
              required
              :error-messages="checkPaymentDepositAmount"
              @input="$v.editOrganisation.payment_deposit_amount.$touch()"
              @blur="$v.editOrganisation.payment_deposit_amount.$touch()"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-text-field
              v-model="editOrganisation.default_percentage_for_shop"
              label="% Increase"
              required
              type="number"
              :error-messages="checkDefaultShopPercentage"
              @input="$v.editOrganisation.default_percentage_for_shop.$touch()"
              @blur="$v.editOrganisation.default_percentage_for_shop.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
              v-model="editOrganisation.fob_location"
              :counter="200"
              label="Fob Location"
              required
              :error-messages="checkFobLocation"
              @input="$v.editOrganisation.fob_location.$touch()"
              @blur="$v.editOrganisation.fob_location.$touch()"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>

          <v-select
              v-model="editOrganisation.state"
              :items="states"
              item-value="id"
              item-text="name"
              label="Org Active"
          ></v-select>
        </v-flex>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="edit_organisation_dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="save_organisation">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="department_user_dialog" persistent width="500px">
      <v-card class="px-5">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                    v-model="department_user.user"
                    :loading="loading"
                    :items="users"
                    item-text="username"
                    item-value="id"
                    :search-input.sync="search_users"
                    cache-items
                    flat
                    hide-no-data
                    hide-details
                    label="User"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                    v-model="department_user.type"
                    :items="department_user_types"
                    item-value="id"
                    item-text="name"
                    label="TYPE"
                ></v-select>
                <input type="hidden" v-model="department_user.department">
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>


        <v-spacer></v-spacer>
        <v-card-actions>

          <v-btn color="green darken-1" text @click="department_user_dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="save_add_new_user">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_department_dialog" persistent width="600px">
      <v-form ref="department_form" v-model="department_form_valid" lazy-validation>
        <v-card class="px-5">
          <v-flex xs12>
            <v-select
                v-model="editDepartment.category"
                :items="department_categories"
                item-value="id"
                item-text="name"
                label="Category"
                :rules="rules.department.category"
                required
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-text-field
                v-model="editDepartment.name"
                :counter="200"
                label="Name"
                required
                :rules="rules.department.name"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
                v-model="editDepartment.address"
                :counter="200"
                label="Address"
                required
                :rules="rules.department.address"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
                v-model="editDepartment.region"
                :counter="200"
                label="Region"
                required
                :rules="rules.department.region"

            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
                v-model="editDepartment.zip_code"
                :counter="14"
                label="Zip Code"
                required
                :rules="rules.department.zip_code"

            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
                v-model="editDepartment.percentage_for_shop"
                label="Department Percentage For Shop"
                required
                type="number"
                :rules="rules.department.percentage_for_shop"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-select
                v-model="editDepartment.state"
                :items="states"
                item-value="id"
                item-text="name"
                label="State"
                required
                :rules="rules.department.state"
            ></v-select>
          </v-flex>
          <v-flex xs12>
          </v-flex>
          <v-flex xs12>
            <input type="hidden" v-model="editDepartment.organisation">
          </v-flex>
          <v-spacer></v-spacer>
          <v-card-actions class="text-right">

            <v-btn color="green darken-1" text @click="edit_department_dialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="save_department()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="sourcer_dialog" width="500">
      <v-card class="pa-5">
        <v-autocomplete
            v-model="sourcer"
            :items="sourcers"
            item-text="username"
            item-value="id"
            cache-items
            flat
            hide-no-data
            hide-details
            label="Sourcer"
        ></v-autocomplete>

        <v-card-actions>
          <v-spacer>

          </v-spacer>
          <v-btn
              color="blue-grey darken-4"
              @click="add_sourcer_to_category(sourcer)"
          >
            Add Sourcer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="change_sourcer_dialog" width="600">
      <v-card>
        <v-card-title>
          Changing Sourcer for {{ change_sourcer_factory.name }}
        </v-card-title>
        <v-card-text>
          <v-select
              v-model="change_sourcer"
              :items="sourcers"
              item-value="id"
              item-text="email"
              label="Sourcer Select"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-grey darken-4"
              @click="save_change_sourcer(change_sourcer_factory, change_sourcer)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {validationMixin} from 'vuelidate'
import {required, maxLength, minLength, email, integer} from 'vuelidate/lib/validators'

export default {
  name: "Organisations",
  mixins: [validationMixin],

  validations: {
    editOrganisation: {
      state: {
        required
      },
      type: {
        required
      },
      payment_terms: {
        required
      },
      payment_deposit_amount: {
        required,
      },
      payment_days: {
        required
      },
      category: {
        required
      },
      store_count: {
        required,
        integer
      },
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(200)
      },
      invoice_code: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(200)
      },
      default_percentage_for_shop: {
        required,
        integer
      },
      fob_location: {
        required,
        maxLength: maxLength(200)
      },
      user_data: {
        profile: {
          address: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(200)
          },
          region: {
            required
          },
          zip_code: {
            required
          },
          country: {
            required
          },
          phone: {
            required
          },
          phone_extension: {
            required
          },
          website: {
            required
          }
        }
      }
    },
  },
  computed: {
    //organisation validations
    checkAddress() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.address.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.address.maxLength && errors.push('.address must be at most x characters long');
      !this.$v.editOrganisation.user_data.profile.address.minLength && errors.push('.address must be at least x characters long');
      !this.$v.editOrganisation.user_data.profile.address.required && errors.push('.address is required.');
      return errors;
    },
    checkRegion() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.region.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.region.required && errors.push('.region is required.');
      return errors;
    },
    checkZipCode() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.zip_code.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.zip_code.required && errors.push('.zip_code is required.');
      return errors;
    },
    checkCountry() {

      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.country.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.country.required && errors.push('.country is required.');
      return errors;
    },
    checkPhone() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.phone.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.phone.required && errors.push('.phone is required.');
      return errors;
    },
    checkPhoneExtension() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.phone_extension.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.phone_extension.required && errors.push('.phone_extension is required.');
      return errors;
    },
    checkWebsite() {
      const errors = [];
      if (!this.$v.editOrganisation.user_data.profile.website.$dirty) return errors;
      !this.$v.editOrganisation.user_data.profile.website.required && errors.push('.website is required.');
      return errors;
    },
    checkState() {
      const errors = [];
      if (!this.$v.editOrganisation.state.$dirty) return errors;
      !this.$v.editOrganisation.state.required && errors.push('.state is required.');
      return errors;
    },
    checkType() {
      const errors = [];
      if (!this.$v.editOrganisation.type.$dirty) return errors;
      !this.$v.editOrganisation.type.required && errors.push('.type is required.');
      return errors;
    },
    checkPaymentTerms() {
      const errors = [];
      if (!this.$v.editOrganisation.payment_terms.$dirty) return errors;
      !this.$v.editOrganisation.payment_terms.required && errors.push('.payment_terms is required.');
      return errors;
    },
    checkPaymentDepositAmount() {
      const errors = [];
      if (!this.$v.editOrganisation.payment_deposit_amount.$dirty) return errors;
      !this.$v.editOrganisation.payment_deposit_amount.required && errors.push('.payment_deposit_amount is required.');
      return errors;
    },
    checkPaymentDays() {
      const errors = [];
      if (!this.$v.editOrganisation.payment_days.$dirty) return errors;
      !this.$v.editOrganisation.payment_days.required && errors.push('.payment_days is required.');
      return errors;
    },
    checkCategory() {
      const errors = [];
      if (!this.$v.editOrganisation.category.$dirty) return errors;
      !this.$v.editOrganisation.category.required && errors.push('.category is required.');
      return errors;
    },
    checkStoreCount() {
      const errors = [];
      if (!this.$v.editOrganisation.store_count.$dirty) return errors;
      !this.$v.editOrganisation.store_count.integer && errors.push('.store_count must be a number value');
      !this.$v.editOrganisation.store_count.required && errors.push('.store_count is required.');
      return errors;
    },
    checkName() {
      const errors = [];
      if (!this.$v.editOrganisation.name.$dirty) return errors;
      !this.$v.editOrganisation.name.maxLength && errors.push('.name must be at most x characters long');
      !this.$v.editOrganisation.name.minLength && errors.push('.name must be at least x characters long');
      !this.$v.editOrganisation.name.required && errors.push('.name is required.');
      return errors;
    },
    checkInvoiceCode() {
      const errors = [];
      if (!this.$v.editOrganisation.invoice_code.$dirty) return errors;
      !this.$v.editOrganisation.invoice_code.maxLength && errors.push('.invoice_code must be at most 10 characters long');
      !this.$v.editOrganisation.invoice_code.minLength && errors.push('.invoice_code must be at least 2 characters long');
      !this.$v.editOrganisation.invoice_code.required && errors.push('.invoice_code is required.');
      return errors;
    },
    checkDefaultShopPercentage() {
      const errors = [];
      if (!this.$v.editOrganisation.default_percentage_for_shop.$dirty) return errors;
      !this.$v.editOrganisation.default_percentage_for_shop.integer && errors.push('.default_percentage_for_shop must be a number value');
      !this.$v.editOrganisation.default_percentage_for_shop.required && errors.push('.default_percentage_for_shop is required.');
      return errors;
    },
    checkFobLocation() {
      const errors = [];
      if (!this.$v.editOrganisation.fob_location.$dirty) return errors;
      !this.$v.editOrganisation.fob_location.maxLength && errors.push('.fob_location must be at most x characters long');
      !this.$v.editOrganisation.fob_location.required && errors.push('.fob_location is required.');
      return errors;
    },
  },
  data: () => ({
    department_form_valid: true,
    rules: {
      department: {
        name: [
          v => !!v || 'Name is required',
          v => (v && v.length <= 200) || 'Name must be less than 200 characters',
          v => (v && v.length > 4) || 'Name must be more than 5 characters',
        ],
        organisation: [
          v => !!v || 'Organisation is required',
        ],
        state: [
          v => !!v || 'State is required',
        ],
        category: [
          v => !!v || 'Cateogry is required',
        ],
        address: [
          v => !!v || 'Address is required',
          v => (v && v.length <= 200) || 'Address must be less than 200 characters',
          v => (v && v.length > 4) || 'Address must be more than 5 characters',
        ],
        region: [
          v => !!v || 'region is required',
          v => (v && v.length <= 200) || 'region must be less than 200 characters'
        ],
        zip_code: [
          v => !!v || 'zip_code is required',
          v => (v && v.length <= 20) || 'zip_code must be less than 20 characters'
        ],
        percentage_for_shop: [
          v => !!v || 'percentage_for_shop is required',
        ],
      }
    },
    change_sourcer_dialog: false,
    sourcer: {},
    states: [
      {
        id: 1,
        name: 'Active',
      },
      {
        id: 2,
        name: 'Disabled'
      }
    ],
    expanded: [],
    singleExpand: false,
    db_link: '',
    editing_fields: [],
    form_type: 'new',
    title: "Users",
    notification: false,
    notification_message: '',
    notification_type: "success",
    organisations: [],
    user: {},
    customer_headers: [
      {
        text: 'Name',
        align: 'center',
        value: 'name',
      },
      {
        text: 'Last Updated',
        align: 'center',
        value: 'last_uploaded_date',
      },
      {
        text: 'Departments',
        align: 'center',
        value: 'departments.length',
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'actions',
      }
    ],
    department_category_headers: [
      {
        text: 'ID',
        align: 'center',
        value: 'id',
      },
      {
        text: 'Name',
        align: 'center',
        value: 'name',
      },
      {
        text: 'Sourcers',
        align: 'center',
        value: 'sourcers',
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'actions',
        custom: true
      },
    ],
    edit_department: {},
    sales: [],
    buyers: [],
    types: [
      {
        id: 0,
        name: 'All'
      },
      {
        id: 1,
        name: 'Factory'
      },
      {
        id: 2,
        name: 'Company / Customer'
      },
      {
        id: 3,
        name: 'Partners'
      },
      {
        id: 5,
        name: 'Sales Manager'
      }
    ],
    filter_fields: [],
    filter_url: '/admins/organisations/',
    init_url: '/admins/organisations/',
    load_url: '/admins/organisations/?type=2',
    organisation_fields: [],
    organisation_categories: [
      {
        id: 1,
        name: 'Department Store'
      },
      {
        id: 2,
        name: 'Supermarkets'
      },
      {
        id: 3,
        name: 'Specialized'
      }
    ],
    organisation_types: [
      {
        id: 1,
        name: 'Factory'
      },
      {
        id: 2,
        name: 'Company / Customer'
      },
      {
        id: 3,
        name: 'Partner'
      },
      {
        id: 5,
        name: 'Sales Manager'
      }
    ],
    payment_terms: [
      {
        id: 1,
        name: 'LC'
      },
      {
        id: 2,
        name: 'TT'
      },
      {
        id: 3,
        name: 'Open Account'
      }
    ],
    payment_deposit: [
      {
        id: 1,
        name: '20%'
      },
      {
        id: 2,
        name: '30%'
      },
      {
        id: 3,
        name: '50%'
      },
      {
        id: 4,
        name: 'Full'
      }
    ],
    payment_days: [
      {
        id: 1,
        name: '30 Days'
      },
      {
        id: 2,
        name: '60 Days'
      },
      {
        id: 3,
        name: '90 Days '
      },
      {
        id: 4,
        name: '120 Days'
      },
      {
        id: 5,
        name: '150 Days'
      }
    ],
    dialog: false,
    defaultOrganisation: {
      id: 0,
      user_data: {profile: {}},
      new_user: false,
      fob_location: 'Shanghai',
      user: {}
    },
    editOrganisation: {
      id: 0,
      user_data: {profile: {}},
      fob_location: 'Shanghai'
    },
    defaultDepartment: {
      id: 0,
      users: []
    },
    editDepartment: {
      id: 0,
      users: []
    },
    edit_organisation_dialog: false,
    edit_department_dialog: false,
    department_user_dialog: false,
    countries: [],
    department: {},
    department_user_types: [
      {
        id: 1,
        name: 'Sales',
        ac_id: 9
      },
      {
        id: 2,
        name: 'Buyer',
        ac_id: 10
      },
      {
        id: 3,
        name: 'Factory',
        ac_id: 6,
      },
      {
        id: 5,
        name: 'Partner',
        ac_id: 13
      },
    ],
    users: [],
    department_user: {
      id: 0,
      user: 0,
      type: 0,
      department: 0
    },
    isLoading: false,
    loading: false,
    search_users: null,
    department_categories: [],
    selected_department_category: {},
    sourcers: [],
    sourcer_dialog: false,
    change_sourcer: {},
    change_sourcer_factory: {}
  }),
  methods: {
    open_change_sourcer(factory) {
      this.change_sourcer_factory = factory;
      this.change_sourcer_dialog = true;
    },
    save_change_sourcer(factory, sourcer) {
      if (confirm('Are you sure you want to change the sourcer for ' + factory.name + ' ?')) {
        let send_data = {
          org_id: factory.id,
          sourcer_id: sourcer
        };

        this.$hpost(this.$http.defaults.baseURL + '/admins/product/change-sourcer/', send_data).then((response) => {
          if (typeof response.data !== 'undefined') {
            this.change_sourcer_dialog = false;
            this.$notify({
              title: 'Success:',
              text: 'Successfully change sourcer',
              group: 'notifications',
              position: 'top right'
            });
          }
        });
      }
    },
    add_sourcer_to_category(sourcer) {

      this.$hpost(
          this.$http.defaults.baseURL + '/admins/department-category-sourcers/', {
            category: this.selected_department_category.id,
            sourcer: sourcer
          }).then((response) => {
        if (typeof response.data.id !== 'undefined') {
          this.$notify({
            title: 'Success:',
            text: 'Successfully added sourcer',
            group: 'notifications',
            position: 'top right'
          });
          this.load_department_categories();
          this.sourcer_dialog = false;
          // this.selected_department_category.sourcers.push()
        }

      });
    },
    edit_category_sourcers(item) {
      this.selected_department_category = item;
      this.sourcer_dialog = true;
    },
    edit_department_data(department, org) {
      this.editOrganisation = org;
      if (typeof department.id === "undefined") {
        this.editDepartment = this.defaultDepartment;
        this.editDepartment.category = 1;
        this.editDepartment.organisation = org.id;
        this.editDepartment.name = 'Department 1 for: ' + org.name;
        this.editDepartment.address = org.address;
        this.editDepartment.region = org.region;
        this.editDepartment.zip_code = org.zip_code;
        this.editDepartment.default_percentage_for_shop = org.default_percentage_for_shop;
      } else {
        this.editDepartment = department;
      }
      this.edit_department_dialog = true;
    },
    view_factory(item) {
      this.$router.push('/c-panel/products/?organisation='+item.id);
    },
    edit_organisation_data(org) {
      if (typeof org.id === 'undefined') {
        org = this.defaultOrganisation;
      }
      this.editOrganisation = org;
      this.edit_organisation_dialog = true;
    },
    use_as_department(department_id) {
      console.log(department_id);
      this.$http.patch('/user/profile/' + this.user.profile_user.id, {department: department_id})
          .then(response => {
            // update storage

            this.user.profile_user.department = department_id;
            // Todo: Use mutation

            localStorage.setItem('user', JSON.stringify(this.user));
            this.notification = true;
            if (!department_id) {
              this.notification_message = 'Successfully Removed use as Department';
            } else {
              this.notification_message = 'Successfully Changed Department';
            }

          });
    },
    async loadOrganisations() {

      if (this.load_url !== -1) {
        let data = await this.$http.get(this.load_url)
            .then(response => response.data.results)
            .catch(this.errorHandler);
        // console.log(data.results);
        // console.log(data);
        // this.organisations = this.organisations.concat(data.results);
        this.organisations = data;
        console.log(data);
        console.log("ORGANISATIONS");

        this.load_url = data.next ? data.next : -1;
        this.load_countries();

      }

    },
    load_department_categories() {
      this.$http.get(
          this.$http.defaults.baseURL + '/admins/department-categories/').then((response) => {
        console.log(response);
        console.log(response.data);
        this.department_categories = response.data;
        this.load_sourcers();
      })
          .catch((error) => {
            this.notification = true;
            this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
          });
    },
    load_sourcers() {
      this.$http.get(
          this.$http.defaults.baseURL + '/admins/users/?group=3&limit=1000').then((response) => {
        console.log(response);
        this.sourcers = response.data.results;
        console.log('sourcers loadded');
        console.log(response.data);
        this.$forceUpdate();

      })
          .catch((error) => {
            this.notification = true;
            this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
          });
    },
    async load_countries() {
      this.$http.get(
          this.$http.defaults.baseURL + '/admins/countries/?limit=2000').then((response) => {
        this.countries = response.data.results;
        if (!this.department_categories.length) {
          this.load_department_categories();
        }
        this.$forceUpdate();
      })
          .catch((error) => {
            console.log("Error");
            console.log(error);
          });
    },
    triggerSearch(parsed_url) {
      console.log("PARSED URL");
      console.log(parsed_url);
      if (!!parsed_url) this.load_url = parsed_url;
      else this.load_url = this.init_url;
      console.log(this.load_url);
      this.invoices = [];
      this.loadOrganisations();
    },
    save_organisation() {
      this.$v.editOrganisation.$touch();

      if (this.$v.editOrganisation.$invalid) {
        this.notification = true;
        this.notification_type = 'red darken-2';
        this.notification_message = 'Please fill the form out correctly';
        return false;
      }

      let profile_data = {
        address: this.editOrganisation.user_data.profile.address,
        region: this.editOrganisation.user_data.profile.region,
        zip_code: this.editOrganisation.user_data.profile.zip_code,
        country: this.editOrganisation.user_data.profile.country,
        phone: this.editOrganisation.user_data.profile.phone,
        phone_extension: this.editOrganisation.user_data.profile.phone_extension,
        website: this.editOrganisation.user_data.profile.website,
        state: this.editOrganisation.state,
        user: this.editOrganisation.user
      };


      if (typeof this.editOrganisation.user_data.profile.id !== 'undefined' && this.editOrganisation.user_data.profile.id > 0) {
        profile_data.id = this.editOrganisation.user_data.profile.id;
      }
      let org_data = {
        id: this.editOrganisation.id,
        state: this.editOrganisation.state,
        type: this.editOrganisation.type,
        payment_terms: this.editOrganisation.payment_terms,
        payment_deposit_amount: this.editOrganisation.payment_deposit_amount,
        payment_days: this.editOrganisation.payment_days,
        category: this.editOrganisation.category,
        store_count: this.editOrganisation.store_count,
        website: this.editOrganisation.website,
        name: this.editOrganisation.name,
        invoice_code: this.editOrganisation.invoice_code,
        default_percentage_for_shop: this.editOrganisation.default_percentage_for_shop,
        fob_location: this.editOrganisation.fob_location,
        user: this.editOrganisation.user
      };

      /**
       * First Save profile, then save organisation
       */
      if (this.editOrganisation.id > 0) {
        this.$http.patch(
            this.$http.defaults.baseURL + '/admins/organisations/' + this.editOrganisation.id + '/', org_data).then((response) => {
          this.save_profile(profile_data);

        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      } else {

        if (this.editOrganisation.new_user === true) {
          profile_data.first_name = this.editOrganisation.first_name;
          profile_data.last_name = this.editOrganisation.last_name;
          profile_data.password = this.editOrganisation.password;
          profile_data.email = this.editOrganisation.email;
          profile_data.username = this.editOrganisation.email;
          profile_data.type = this.editOrganisation.type;
          console.log(this.editOrganisation);
          console.log(profile_data);


          // add new profile then add organisation
          this.add_new_organisation_user(profile_data).then((result) => {
            this.editOrganisation.user = result.data.id;
            this.editOrganisation.new_user = false;
            this.users.push(result.data);

            org_data.user = result.data.id;
            org_data.unique_id = org_data.name.toLowerCase().trim().replace(' ', '');
            console.log("RESULT");
            console.log(result);
            this.add_new_organisation(org_data);
          }).catch((error) => {
            this.notification = true;
            this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
            return false;
          });
        } else {
          org_data.unique_id = org_data.name.toLowerCase().trim().replace(' ', '');
          this.add_new_organisation(org_data);
        }


      }

    },
    add_new_organisation(org_data) {

      console.log(org_data);

      this.$http.post(
          this.$http.defaults.baseURL + '/admins/organisations/', org_data).then((response) => {
        console.log(response);
        this.organisations.push(response.data);
        this.edit_organisation_dialog = false;
        this.notification = true;
        this.notification_message = 'Successfully Created Organisation';
        this.notification_type = 'success';
      })
          .catch((error) => {
            this.notification = true;
            this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
          });
    },
    add_new_organisation_user(profile_data) {
      /**
       * Add user then add profile,
       */
      return this.$http.post('/admins/users/', profile_data);
    },
    save_profile(profile) {
      console.log(profile);
      if (typeof profile.id !== 'undefined') {
        this.$http.patch(
            this.$http.defaults.baseURL + '/admins/user-profiles-default/' + profile.id + '/', profile).then((response) => {
          this.notification = true;
          this.notification_message = 'Successfully saved organisation';
          this.notification_type = 'success';
          this.edit_organisation_dialog = false;
        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      } else {
        this.$http.post(
            this.$http.defaults.baseURL + '/admins/user-profiles-default/', profile).then((response) => {
          this.notification = true;
          this.notification_message = 'Successfully saved organisation';
          this.notification_type = 'success';
          this.loadOrganisations();
          this.edit_organisation_dialog = false;
        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      }
    },
    remove_user(user, dep, ind) {
      if (confirm('Are you sure you would like to remove this user from department: ' + dep.name + '?')) {
        let del_data = {
          user_id: user.id,
          dep_id: dep.id
        };
        this.$http.patch(
            this.$http.defaults.baseURL + '/admins/department-users/1/', del_data).then((response) => {
          this.notification = true;
          this.notification_message = 'Successfully removed user';
          this.notification_type = 'success';
          dep.users.splice(ind, 1);
        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      }

    },
    add_new_user(dep) {
      console.log(dep);
      this.department = dep;
      this.department_user.department = dep.id;
      this.department_user_dialog = true;
    },
    save_add_new_user() {
      let send_data = {
        user: this.department_user.user,
        department: this.department_user.department,
        type: this.department_user.type
      };

      this.$http.post(
          this.$http.defaults.baseURL + '/admins/department-users/', send_data).then((response) => {
        console.log(response);
        this.department.users = response.data;
        this.notification = true;
        this.notification_message = 'Successfully added user.';
        this.department_user_dialog = false;

      })
          .catch((error) => {
            this.notification = true;
            this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
          });
    },
    save_department() {

      if (!this.$refs.department_form.validate()) {
        return false;
      }

      if (this.editDepartment.id > 0) {
        this.$http.patch(
            this.$http.defaults.baseURL + '/admins/departments/' + this.editDepartment.id + '/', this.editDepartment).then((response) => {
          console.log(response);
          this.notification = true;
          this.notification_type = 'success';
          this.notification_message = 'Successfully Saved Department';
          this.edit_department_dialog = false;

        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      } else {
        this.editDepartment.unique_id = this.generate_unique_id(this.editDepartment.name);
        this.$http.post(
            this.$http.defaults.baseURL + '/admins/departments/', this.editDepartment).then((response) => {

          this.notification = true;
          this.notification_message = 'Successfully Added Department';
          if (!this.editOrganisation.departments) {
            this.editOrganisation.departments = [];
          }
          response.data.users = [];
          this.editOrganisation.departments.push(response.data);
          this.editDepartment.id = response.data.id;
          this.edit_department_dialog = false;
        })
            .catch((error) => {
              this.notification = true;
              this.notification_message = 'Error: Please contact administrator';
              console.log("Error");
              console.log(error);
            });
      }


    },
    generate_unique_id(name) {
      return name.toLowerCase().replace(/[^0-9a-zA-Z]/g, '');
    }
  },
  watch: {
    search_users(val) {

      if (!val || val === '') return;

      if (this.isLoading) return;

      this.isLoading = true;
      console.log('reload');
      this.$http.get(
          this.$http.defaults.baseURL + '/admins/users/?username=' + val).then((response) => {
        this.users = response.data.results;
        this.isLoading = false;
      })
          .catch((error) => {
            // this.notification = false;
            // this.notification_message = 'Error: Please contact administrator';
            console.log("Error");
            console.log(error);
          }).finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.filter_fields = [
      {
        table: 'name',
        label: 'Name',
        type: 'text',
        value: '',
        required: true,
        rules: []
      },
      {
        table: 'type',
        label: 'Type',
        type: 'select',
        value: 0,
        required: true,
        rules: [],

        options: this.types,
        track_by: 'id',
        show: 'name',
      }
    ];

    this.user = this.$store.getters.user;
    console.log("USER");
    console.log(this.user);
    this.loadOrganisations();
  }
}
</script>

<style scoped lang="scss">
.inset_top_shadow {
  -moz-box-shadow: 0 5px 6px -6px #000000AA inset;
  -webkit-box-shadow: 0 5px 6px -6px #000000AA inset;
  box-shadow: 0 5px 6px -6px #000000AA inset;
  padding: 8px 2px 0 2px;
}

.inset_bottom_shadow {
  -moz-box-shadow: 0 -6px 6px -6px #000000AA inset;
  -webkit-box-shadow: 0 -6px 6px -6px #000000AA inset;
  box-shadow: 0 -6px 6px -6px #000000AA inset;
  padding: 0 2px 8px 2px;
}

</style>
